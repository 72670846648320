class HomeSlider extends Component {
	constructor(el) {
		super(el);
		this.swiperEl = this.$find('[data-swiper]');
		this.swiper = null;
		this.pagerItems = this.$findAll('[data-pager-item]');
		this.swiperEl && this.constructSwiper();
		this.pagerItems.length && this.initCustomPager();
	}

	constructSwiper() {
		this.swiper = new Swiper(this.swiperEl, {
			watchOverflow: true,
			pagination: {
				el: '[data-home-pagination]',
				clickable: true,
				renderBullet: (index, className) => {
					return '<span class="' + className + '">' + (index + 1) + '</span>';
				}
			},
			on: {
				init: () => {
					this.pagerItems.item(0).classList.add('active');
				},
				slideChange: () => {
					this.pagerItems.forEach(item => item.classList.remove('active'));
					this.pagerItems.item(this.swiper.activeIndex).classList.add('active');
				}
			}
		});
	}

	initCustomPager() {
		this.pagerItems.forEach(item => {
			item.addEventListener('mouseenter', e => {
				this.swiper.slideTo(Util.elIndex(item));
			});
		});
	}
}
